import React, { useState, useCallback } from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import styled from 'styled-components';

import {pixels as breakpoints} from '../config/breakpoints';
import Layout from "../component/Layout/Layout";

const OwnGallery = ({ photos }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <>
            <Gallery
                photos={photos}
                direction={"column"}
                onClick={openLightbox}
            />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </ >
    )
}

const GalleryWrapper = styled.div`
    padding: 20px 2em 10px;
    @media (min-width: ${breakpoints.tablet}) {
        padding: 100px 7em 50px;
    }
`

const GalleryPage = () => (
    <Layout>
        <GalleryWrapper>
        <h1>Gallery</h1>
        <OwnGallery photos={photos} />
        </GalleryWrapper>
    </Layout>
)

export default GalleryPage;

const photos = [
    {
        src: "/img/gallery/gallery01.jpg",
        width: 3,
        height: 4
    },
    {
        src: "/img/gallery/gallery02.jpg",
        width: 3,
        height: 5
    },
    {
        src: "/img/gallery/gallery03.jpg",
        width: 3,
        height: 4
    },
    {
        src: "/img/gallery/gallery04.jpg",
        width: 5,
        height: 6
    },
    {
        src: "/img/gallery/gallery05.jpg",
        width: 5,
        height: 6
    },
    {
        src: "/img/gallery/gallery07.jpg",
        width: 3,
        height: 4
    },
    {
        src: "/img/gallery/gallery08.jpg",
        width: 3,
        height: 4
    },
    {
        src: "/img/gallery/gallery09.jpg",
        width: 5,
        height: 3
    },
    {
        src: "/img/gallery/gallery10.jpg",
        width: 1,
        height: 1
    },
    {
        src: "/img/gallery/gallery11.jpg",
        width: 3,
        height: 4
    },
    {
        src: "/img/gallery/gallery12.jpg",
        width: 1,
        height: 1
    },
    {
        src: "/img/gallery/gallery13.jpg",
        width: 3,
        height: 4
    },
    {
        src: "/img/gallery/gallery14.jpg",
        width: 1,
        height: 1
    },
    {
        src: "/img/gallery/gallery15.jpg",
        width: 3,
        height: 5
    },
    {
        src: "/img/gallery/gallery16.jpg",
        width: 3,
        height: 5
    },
    {
        src: "/img/gallery/gallery17.jpg",
        width: 5,
        height: 3
    },
    {
        src: "/img/gallery/gallery18.jpg",
        width: 3,
        height: 4
    },
    {
        src: "/img/gallery/gallery19.jpg",
        width: 3,
        height: 4
    },
    {
        src: "/img/gallery/gallery20.jpg",
        width: 23,
        height: 26
    },
    {
        src: "/img/gallery/gallery21.jpg",
        width: 3,
        height: 4
    },
    {
        src: "/img/gallery/gallery22.jpg",
        width: 3,
        height: 4
    },
    {
        src: "/img/gallery/gallery23.jpg",
        width: 4,
        height: 3
    },
    {
        src: "/img/gallery/gallery24.jpg",
        width: 4,
        height: 3
    },
    {
        src: "/img/gallery/gallery25.jpg",
        width: 30,
        height: 37
    },
    {
        src: "/img/gallery/gallery26.jpg",
        width: 12,
        height: 10
    }
];
